.has-search .form-control {
    padding-left: 2.375rem;
    /* float: right; */
}
.has-search{
    position: relative;
}
.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: black;
    padding: 10px;
}
.has-search input{
    color: black;
    width: 25%;
}
.has-search input:focus{
    outline: none;
    border: 1px solid gray;
}
.pagination{
    margin-top: 20px;
    display: flex;
    justify-content: end;
   
}
.pagination li.active {
    color: #ffff; 
    background-color: #394150; 
    transition: scale 0.3s ease-in-out;
  }
  .pagination li.active:hover {
    
    scale: 1.07;
  }