/* src/styles/tailwind.css */

.input {
  @apply w-full px-4 py-2 border rounded-lg bg-gray-100 shadow-inner focus:outline-none focus:ring focus:border-blue-300;
}

.btn {
  @apply bg-blue-500 text-white font-semibold px-6 py-2 rounded-lg shadow-md hover:bg-gray-700 transition duration-300;
}

@tailwind utilities;
.navbar{
  border-bottom: 1px solid #f0f0f0;
}
.nav-item .nav-link{
  color: black;
  background-color: #fff;
  font-weight: 700;
  font-size: 20px;
}
.main{
  background-image: url(../../assets/backgroundImg.jpg);
  background-size: cover;
  /* background-position: center; */
  background-repeat: no-repeat;
  /* height: 100vh; */
}
.text-container .heading{
/* font-size: 26px; */
font-weight: 700;
line-height: 39px;

}
.text-container .content{
/* font-size: 16px; */
font-weight: 500;
line-height: 24px;
}
.form-container{
  border: 3px solid #0000;
  border-radius: 50px;
}
.form-input{
  border: 1px solid #000;
  color: black;
}
.form-input:focus{
outline: none;
}
.btn{
  transition: 0.3s ease-in-out;

}
.btn:hover{
  scale: 1.1;

}
/* Add transition styles */
.nav-menu {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.nav-menu.show {
  opacity: 1;
  visibility: visible;
}
