.sidebar{
    height: 100vh;
}

.sidebarLink.active{
    padding: 10px;
    background-color: #36454F;
    border-radius: 10px;
    font-weight: 600;
}
.sidebarLink{
    transition: 0.3s ease-in-out;
}

.sidebarLink:hover{
    scale: 1.1;
}